<template>
    <div class="grid grid-cols-1 mb-4">
        <div class="col-span-1 flex items-center mb-1">
            <div class=" text-xs mr-1 text-grey-2 font-bold inline">Status</div>
            <div class="uppercase font-semibold text-xs" :class="getStatusClass(value.status)">
                {{ getStatusTitle(value.status) }}
            </div>
        </div>
        <div class="col-span-1 flex items-center mb-1">
            <div class=" text-xs mr-1 text-grey-2 font-bold inline">
                Number of open episodes
            </div>
            <div class="font-semibold text-xs">{{ value.openEpisodeCount }}</div>
        </div>
        <div class="col-span-1 flex items-center mb-1" v-show="dateInvited">
            <div class=" text-xs mr-1 text-grey-2 font-bold inline">
                Date of last invite
            </div>
            <div class="font-semibold text-xs">{{dateInvited}}</div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {StuniHelper} from '@/components/shared/mixins/stuniMixin'
import {parseISO, format} from 'date-fns'

export default {
    name: 'StuniForm',
    mixins: [StuniHelper],
    props: {
        value: {
            type: Object,
            default: () => {}
        }
    },
    methods: {},
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        dateInvited() {
            return this.value.dateInvited && format(parseISO(this.value.dateInvited), 'dd/MM/yyyy HH:mm:ss')
        }
    }
}
</script>
