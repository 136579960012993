<template>
    <v-form v-model="isValid" ref="form">
        <div class="grid grid-cols-4 gap-x-4 w-full">
            <div class="col-span-1">
                <v-text-field
                    label="First name"
                    v-model="form.firstName"
                    :rules="[required]"
                    :readonly="readOnly"
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <v-text-field
                    label="Last name"
                    v-model="form.lastName"
                    :rules="[required]"
                    :readonly="readOnly"
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <v-select
                    :items="stuniGenders"
                    v-model="form.profile.gender"
                    label="Gender"
                    :rules="[required]"
                    :readonly="readOnly"
                ></v-select>
            </div>
            <div class="col-span-1">
                <EditableDatePicker
                    label="D.O.B"
                    v-model="form.profile.dob"
                    :rules="[required]"
                    :max="maximumDob"
                    :min="minimumDob"
                    :readOnly="readOnly"
                />
            </div>
        </div>
        <div class="grid grid-cols-4 gap-x-4 w-full">
            <div class="col-span-1">
                <v-text-field
                    label="Email"
                    v-model="form.email"
                    :rules="[required]"
                    :readonly="readOnly"
                ></v-text-field>
            </div>
            <div class="col-span-2">
                <PhoneNumber
                    name="phone"
                    v-model="phone"
                    ref="phone"
                    :readOnly="readOnly"
                />
            </div>
            
            <div class="col-span-1">
                <v-select
                    :items="stuniRoles"
                    v-model="form.role"
                    label="Role"
                    :readonly="form.status != 'ACTIVE'"
                ></v-select>
            </div>
        </div>
        <h4 class="font-display font-semibold text-left text-xl mt-4">Address</h4>
        <div class="grid grid-cols-2 gap-x-4 w-full">
            <div class="col-span-1">
                <Address ref="addressForm" v-model="address" :readOnly="readOnly" />
            </div>
        </div>
        <div v-if="approved">
            <h4 class="font-display font-semibold text-left text-xl my-4">History</h4>
            <div class="text-sm mx-2 w-full">
                {{`Approved as ${ isGuest ? 'guest' : 'client' } on ${dateApproved} by `}}
                <span class="font-weight-bold">{{ approvedBy }}.</span>
            </div>
            <div class="text-sm mx-2 my-2 w-full">
                Comments: {{ comment }}
            </div>
        </div>
    </v-form>
</template>

<script>
import {mapState} from 'vuex'
import { format } from 'date-fns'
import {StuniHelper, StuniStatus} from '@/components/shared/mixins/stuniMixin'
import {FormRules} from '@/components/shared/mixins/formMixins'
import PhoneHelper from '@/components/shared/mixins/phoneHelper'
import DOBHelper from '@/components/shared/mixins/dobMixin'
import Address from '@/components/shared/Address'

export default {
    name: 'Form',
    components: {Address},
    mixins: [StuniHelper, FormRules, DOBHelper],
    props: {
        value: {
            type: Object,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isValid: true,
            phone: {
                countryCode: 'AU',
                phone: ''
            },
            address: {
                unit: '',
                addressLine1: '',
                addressLine2: '',
                suburb: '',
                postcode: '',
                state: '',
                lat: null,
                lng: null,
                archived: false
            }
        }
    },
    methods: {
        validate() {
            this.isValid = this.$refs.form.validate() && this.$refs.addressForm.validate()
            return this.isValid
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        form: {
            get() {
                return this.value
            },
            set(form) {
                this.$emit('input', form)
            }
        },
        dateApproved() {
            const date = this.value.dateApproved
            return date ? format(new Date(date), 'MMM dd, yyyy') : ''
        },
        approved() {
            return this.value.status === StuniStatus.ACTIVE
        },
        approvedBy() {
            return this.value.approvingUser?.name
        },
        isGuest() {
            return this.value.role === 'guest'
        },
        comment() {
            return this.value.comment
        }
    },
    watch: {
        form() {
            this.phone = PhoneHelper.parse(this.form.profile.phone)
            this.address = {...this.form.profile.address}
        },
        phone: {
            handler() {
                this.form.profile.phone = this.phone?.phone || ''
            },
            deep: true
        },
        address: {
            handler() {
                this.form.profile.address = {...this.address}
            },
            deep: true
        },
        
    }
}
</script>
