<template>
    <div class="container flex flex-col">
        <div class="mx-auto w-full max-w-960 mt-8 mb-8 p-4 bg-white-pure l-box-shadow rounded">
            <div class="mb-8">
                <BackLink :backRoute="stuniListRoute" :linkText="goBackText" />
            </div>

            <h4 class="font-display font-semibold text-xl mb-4">Stuni Details</h4>
            <StuniSummary v-model="user"/>

            <v-form>
                <StuniForm v-model="stuniForm" ref="stuniForm" :readOnly="readOnly" />

                <div
                    class="py-3 px-4 mt-2 bg-yellow-light rounded flex items-center"
                >
                    <fa-icon icon="exclamation-triangle" class="text-yellow-dark mr-1"></fa-icon>
                    <div class="text-sm text-yellow-dark">
                        Changing the address and other details will update the client profile.
                    </div>
                </div>
                <div class="flex flex-row justify-center mt-8">
                    <router-link
                        class="mr-4 px-6 v-btn v-btn--depressed theme--light v-size--default cursor-pointer router-link-active bg-grey-4"
                        :to="stuniListRoute"
                    ><span class="text-black">Cancel</span></router-link>

                    <StuniDetailActions :user="user" @perform-action="performAction" />
                    
                    <v-btn
                        depressed
                        class="v-btn--flat inline w-30"
                        type="success"
                        color="success"
                        :disabled="!isValidAction(user.status, 'UPDATE_INFO') || readOnly"
                        @click.prevent="saveHandler"
                    >Save</v-btn>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import BackLink from '@/components/shared/BackLink'
import GET_STUDENT from '@/graphql/queries/getStudent.gql'
import UPDATE_USER_STATUS from '@/graphql/mutations/updateUserStatus.gql'
import DEACTIVATE_USER from '@/graphql/mutations/deactivateUser.gql'
import REACTIVATE_USER from '@/graphql/mutations/reactivateUser.gql'
import APPROVE_STUNI from '@/graphql/mutations/approveStuni.gql'
import APPROVE_GUEST_STUNI from '@/graphql/mutations/approveGuestStuni.gql'
import REJECT_STUNI from '@/graphql/mutations/rejectStuni.gql'
import REINVIET_USER from '@/graphql/mutations/reinviteUser.gql'
import GET_ALL_CLIENTS from '@/graphql/queries/getAllClients.gql'
import UPDATE_STUDENT from '@/graphql/mutations/updateStudent.gql'
import UPDATE_STUDENT_AND_CLIENT from '@/graphql/mutations/updateStudentAndClient.gql'
import INVITE_TO_STUNI from '@/graphql/mutations/inviteToStuni.gql'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { mapGetters, mapState } from 'vuex'
import StuniSummary from './partials/StuniSummary'
import StuniForm from './partials/StuniForm'
import {StuniHelper, StuniStatus, StuniAction} from '@/components/shared/mixins/stuniMixin'
import {UserHelper} from '@/components/shared/mixins/userMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import StuniDetailActions from '@/components/stuni-management/partials/StuniDetailActions'

const STUNI_DEFAULT_USER = {
    role: '',
    firstName: '',
    lastName: '',
    email: '',
    profile: {
        gender: '',
        dob: '',
        phone: '',
        address: {}
    }
}

export default {
    name: 'StuniDetails',
    components: {BackLink, StuniSummary, StuniForm, StuniDetailActions},
    mixins: [StuniHelper, MessageDialog, UserHelper],
    data() {
        return {
            goBackText: 'Back to Stuni Management',
            stuniListRoute: {name: 'stuni-management'},
            loading: false,
            userId: this.$route.params.userId,
            user: STUNI_DEFAULT_USER,
            clients: []
        }
    },
    apollo: {
        user: {
            query: GET_STUDENT,
            variables() {
                return {
                    userId: this.userId
                }
            },
            result({data: {user}}) {
                user = camelcaseKeys(user[0] || {}, {deep: true})

                if (user.locationPrograms.length) {
                    user.locationPrograms = user.locationPrograms.map(
                        ({locationProgram}) => locationProgram
                    )
                    user.locations = user.locationPrograms.reduce((locations, {location}) => {
                        if (!locations.find(loc => loc.locationId === location.locationId))
                            locations.push(location)
                        return locations
                    }, [])
                }

                user.profile = user.profile == null ? user.client ?? user.initialProfile : user.profile
                user.clientId = user.client?.clientId
                user.openEpisodeCount = user.client?.openEpisodeCountAggregate?.aggregate?.count ?? 0
                console.log('user loaded', user, this.user)

                if (this.outsideSiteSection(user)) {
                    this.user = {}
                    this.message = 'You do not have permission to view this page'
                    this.type = 'error'
                    this.showMessage({duration: 5000})
                    this.$router.push(this.stuniListRoute)
                } else {
                    this.user = user
                }
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        clients: {
            query: GET_ALL_CLIENTS,
            variables() {
                return {}
            },
            result({ data: {clients} }) {
                clients.forEach(client => {
                    client.name = `${client.first_name} ${client.last_name}`
                })
                this.clients = camelcaseKeys(clients || [], { deep: true })
            },
            watchLoading (isLoading) {
                this.loading = isLoading
            },
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'stuni-management') {
                vm.stuniListRoute = from;
            }
        });
    },
    mounted() {
        this.$root.$on('change-user-status', this.changeStuniStatus)
    },
    methods: {
        saveHandler() {
            if (!this.$refs.stuniForm.validate()) return

            this.saveStuniUser()
        },
        saveStuniUser() {
            const hasClient = this.user.clientId > 0
            const variables = {userId: this.user.userId}
            const user = (({role, firstName, lastName, email}) => ({
                role,
                firstName,
                lastName,
                name: `${firstName} ${lastName}`,
                email
            }))(this.stuniForm)
            
            variables.auth0User = snakecaseKeys(user, {deep: true})

            if (!hasClient) {
                user.initialProfile = {
                    ...this.stuniForm.profile,
                    address: {...this.stuniForm.profile.address}
                }
            }
            variables.user = snakecaseKeys(user, {deep: true})
            if (hasClient) {
                variables.clientId = this.user.clientId
                const { email, firstName, lastName } = this.stuniForm
                const {gender, dob, phone} = this.stuniForm.profile
                variables.client = snakecaseKeys(
                    { gender, dob, phone, email, firstName, lastName },
                    {deep: true}
                )
                variables.addressId = this.user.client.addressId
                console.log(this.user.client)
                const {unit, addressLine1, addressLine2, postcode, state, suburb} = this.stuniForm.profile.address
                variables.address = snakecaseKeys(
                    {unit, addressLine1, addressLine2, postcode, state, suburb},
                    {deep: true}
                )
            }

            this.$apollo
                .mutate({
                    mutation: !hasClient ? UPDATE_STUDENT : UPDATE_STUDENT_AND_CLIENT,
                    variables: variables
                })
                .then(res => {
                    this.$apollo.queries.user.refetch()
                    this.saving = false
                    this.message = 'The student profile has been updated.'
                    this.type = 'success'
                })
                .catch(err => {
                    let message = err?.graphQLErrors[0]?.message
                    if (!message) message = 'Error updating the student profile'
                    this.message = message
                    this.type = 'error'
                })
                .finally(() => {
                    this.saving = false
                    this.showMessage({duration: 5000})
                })
        },
        isValidAction(status, action) {
            return this.getStuniValidActions(status).includes(action)
        },
        performAction(user = {}, action) {
            const { userId } = user

            switch (action) {
                case StuniAction.REACTIVATE:
                    this.activateUser(userId, StuniAction.REACTIVATE)
                    break
                case StuniAction.DEACTIVATE:
                    this.deactivateUser(userId, StuniAction.DEACTIVATE)
                    break
                case StuniAction.APPROVE_AS_CLIENT:
                    this.approveAsClientStuni(userId, StuniAction.APPROVE_AS_CLIENT)
                    break
                case StuniAction.APPROVE_AS_GUEST:
                    this.approveAsGuestStuni(userId, StuniAction.APPROVE_AS_GUEST)
                    break
                case StuniAction.REJECT:
                    this.rejectStuni(userId, StuniAction.REJECT)
                    break
                case StuniAction.MARK_AS_PENDING_APPROVAL:
                    this.markAsPendingApprovalStuni(userId, StuniAction.MARK_AS_PENDING_APPROVAL)
                    break
                case StuniAction.REINVITE:
                    this.reinviteUser(userId, StuniAction.REINVITE)
                    break
                case StuniAction.LINK_TO_STUNI:
                    this.linkToStuni(user)
                    break
                default:
                    console.log(action)
            }
        },
        changeStuniStatus({variables, action}) {
            let mutation

            switch(action) {
                case StuniAction.DEACTIVATE:
                    mutation = DEACTIVATE_USER
                    break
                case StuniAction.REACTIVATE:
                    mutation = REACTIVATE_USER
                    break
                case StuniAction.APPROVE_AS_CLIENT:
                    mutation = APPROVE_STUNI
                    break
                case StuniAction.APPROVE_AS_GUEST:
                    mutation = APPROVE_GUEST_STUNI
                    break
                case StuniAction.REJECT:
                    mutation = REJECT_STUNI
                    break
                case StuniAction.MARK_AS_PENDING_APPROVAL:
                    mutation = UPDATE_USER_STATUS
                    break
                case StuniAction.REINVITE:
                    mutation = REINVIET_USER
                    break
                default:
                    mutation = null
                    console.log(action)
            }

            if (mutation) {
                if (mutation === UPDATE_USER_STATUS) {
                    variables.status = StuniStatus.PENDING_APPROVAL
                }

                this.$apollo
                    .mutate({
                        mutation,
                        variables
                    })
                    .then(async res => {
                        await this.$apollo.provider.defaultClient.resetStore()

                        this.message = 'The student status has been updated.'
                        this.type = 'success'
                    }).catch(err => {
                        let message = err?.graphQLErrors[0]?.message
                        if (!message) message = 'Error updating the student profile'
                        this.message = message
                        this.type = 'error'
                    }).finally(() => {
                        this.showMessage({duration: 5000})
                    })
            }
        },
        outsideSiteSection(user) {
            let locationProgramIds = []

            if (user?.client?.episodes?.length) {
                user.client?.episodes.forEach(
                    ({locationProgram: {locationProgramId}}) => {
                        locationProgramIds.push(locationProgramId)
                    }
                )
            } else {
                if (user?.campusId) locationProgramIds.push(user.campusId)
            }

            return !locationProgramIds.some(locationProgramId => this.loggedInUser.locationProgramIds.includes(locationProgramId))
        }
    },
    computed: {
        ...mapGetters(['userIdToLink']),
        ...mapState({
            loggedInUser: state => state.app.loggedInUser
        }),
        stuniForm: {
            get() {
                const form = {...this.user}
                form.profile = form.profile ? {...form.profile} : {}
                form.profile.address = form.profile.address ? {...form.profile.address} : {}

                return form
            },
            set(form) {
                this.$emit('input', form)
            }
        },
        readOnly() {
            return this.user.status === StuniStatus.DEACTIVATED
        }
    },
    watch: {
        userIdToLink() {
            if (!this.userIdToLink) return
            console.log('updating user', this.userIdToLink)
            this.$apollo
                .mutate({
                    mutation: INVITE_TO_STUNI,
                    variables: {
                        userId: this.userIdToLink
                    }
                })
                .then(res => {
                    this.$apollo.queries.user.refetch()
                    this.saving = false
                    this.message = 'The student profile has been invited.'
                    this.type = 'success'
                })
                .catch(err => {
                    let message = err?.graphQLErrors[0]?.message
                    if (!message) message = 'Error inviting user to stuni'
                    this.message = message
                    this.type = 'error'
                })
                .finally(() => {
                    this.saving = false
                    this.showMessage({duration: 5000})
                    this.$store.dispatch('setUserIdToLink', '')
                })
        }
    },
    destroyed() {
        this.$root.$off('change-user-status')
    }
}
</script>
