<template>
    <div>
        <!-- <v-btn depressed class="mr-2 v-btn--flat inline danger bg-red-light">
        <fa-icon icon="trash-alt" class="mr-1" />
        Delete
    </v-btn> -->
        <v-btn
            color="primary"
            depressed
            class="mr-4 v-btn--flat inline"
            v-if="isValidAction(currentUser.status, StuniAction.APPROVE_AS_CLIENT)"
            @click.prevent="performAction(emit, currentUser, StuniAction.APPROVE_AS_CLIENT)"
        >
            <fa-icon icon="check" class="mr-1" />
            Approve as Client
        </v-btn>
        <v-btn
            color="primary"
            depressed
            class="mr-4 v-btn--flat inline"
            v-if="isValidAction(currentUser.status, StuniAction.APPROVE_AS_GUEST)"
            @click.prevent="performAction(emit, currentUser, StuniAction.APPROVE_AS_GUEST)"
        >
            <fa-icon icon="check" class="mr-1" />
            Approve as Guest
        </v-btn>
        <v-btn
            color="primary"
            depressed
            class="mr-4 v-btn--flat inline"
            v-if="isValidAction(currentUser.status, StuniAction.REACTIVATE)"
            @click.prevent="performAction(emit, currentUser, StuniAction.REACTIVATE)"
        >
            <fa-icon icon="check" class="mr-1" />
            Reactivate
        </v-btn>
        <v-btn
            depressed
            class="mr-4 v-btn--flat inline text-orange bg-orange-light"
            v-if="isValidAction(currentUser.status, StuniAction.DEACTIVATE)"
            @click.prevent="performAction(emit, currentUser, StuniAction.DEACTIVATE)"
        >
            <fa-icon icon="times" class="mr-1" />
            Deactivate
        </v-btn>
        <v-btn
            color="primary"
            depressed
            class="mr-4 v-btn--flat inline"
            v-if="isValidAction(currentUser.status, StuniAction.REINVITE)"
            @click.prevent="performAction(emit, currentUser, StuniAction.REINVITE)"
        >
            <fa-icon icon="envelope" class="mr-1" />
            Re-Invite
        </v-btn>
        <v-btn
            depressed
            color="danger"
            class="mr-4 v-btn--flat inline text-red bg-red-light"
            v-if="isValidAction(currentUser.status, StuniAction.REJECT)"
            @click.prevent="performAction(emit, currentUser, StuniAction.REJECT)"
        >
            <fa-icon icon="times" class="mr-1" />
            Reject
        </v-btn>
        <v-btn
            depressed
            class="mr-4 v-btn--flat inline bg-yellow-light"
            v-if="isValidAction(currentUser.status, StuniAction.MARK_AS_PENDING_APPROVAL)"
            @click.prevent="performAction(emit, currentUser, StuniAction.MARK_AS_PENDING_APPROVAL)"
        >
            <fa-icon icon="check" class="text-yellow-dark mr-1" />
            <span class="text-yellow-dark">Mark As Pending Approval</span>
        </v-btn>
        <v-btn
            depressed
            color="success"
            class="mr-4 v-btn--flat inline"
            v-if="isValidAction(currentUser.status, StuniAction.UNLOCK)"
            @click.prevent="performAction(emit, currentUser, StuniAction.UNLOCK)"
        >
            <fa-icon icon="times" class="mr-1" />
            Unlock
        </v-btn>
        <v-btn
            depressed
            color="lightblue"
            class="mr-4 v-btn--flat inline hidden bg-light-blue-light"
            v-if="isValidAction(currentUser.status, StuniAction.CHANGE_PASSWORD)"
            @click.prevent="performAction(emit, currentUser, StuniAction.CHANGE_PASSWORD)"
        >
            <fa-icon icon="redo" class="mr-1" />
            Change Password
        </v-btn>
        <router-link
            :to="{name: 'client-overview', params: {clientId: currentUser.clientId}}"
            class="mr-4 v-btn v-btn--depressed theme--light v-size--default cursor-pointer primary hover:text-light-blue"
            v-if="currentUser.clientId && isValidAction(currentUser.status, StuniAction.MANAGE_CLIENT)"
        >
            <fa-icon icon="external-link-alt" class="mr-1" />
            Manage Client
        </router-link>
    </div>
</template>

<script>
import {StuniAction, getStuniValidActions} from '@/components/shared/mixins/stuniMixin'
import { ref, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useGetters } = createNamespacedHelpers('')

const isValidAction = (status, action) => {
    return getStuniValidActions(status).includes(action)
}
const performAction = (emit, user, action) => {
    emit('perform-action', user, action)
}

export default {
    name: 'StuniDetailActions',
    props: {
        user: {
            type: Object,
            default: () => {}
        }
    },
    emits: ['perform-action'],
    setup(props, { emit }) {
        const { userIdToLink } = useGetters(['userIdToLink'])
        const currentUser = ref(props.user)
        watch(() => props.user, (first, _) => {
            currentUser.value = first
        })
        return {
            currentUser,
            emit,
            StuniAction,
            userIdToLink,
            performAction,
            isValidAction
        }
    }
}
</script>

